// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-index-page-js": () => import("./../src/templates/indexPage.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-media-page-js": () => import("./../src/templates/mediaPage.js" /* webpackChunkName: "component---src-templates-media-page-js" */),
  "component---src-templates-lab-page-js": () => import("./../src/templates/labPage.js" /* webpackChunkName: "component---src-templates-lab-page-js" */),
  "component---src-templates-publications-page-js": () => import("./../src/templates/publicationsPage.js" /* webpackChunkName: "component---src-templates-publications-page-js" */)
}

